import packageInfo from '@/../package.json';
import mixpanel from 'mixpanel-browser';

const isEnabled = process.env.VUE_APP_MIXPANEL_TOKEN && process.env.VUE_APP_ENV !== 'test'

export const init = () => {
    console.log('MIXPANEL enable', isEnabled);

    if (!isEnabled)
        return;

    mixpanel.init(process.env.VUE_APP_MIXPANEL_TOKEN, {
        debug: false,
        loaded: function () {
            window.mixpanelProperties = mixpanel.persistence.properties();
        }
    });
}

export const identify = (user) => {
    handleDistinctId(user)
    registerSuperProps(user)

    if (!user)
        return;

    mixpanel.identify(user.id);
    mixpanel.set_group("company", user.corretoraId);
    mixpanel.get_group("company", user.corretoraId).set({
        $name: user.corretora,
        trial: user.assinatura.trial,
        cidade: user.cidade
    })

    mixpanel.people.set({
        $name: user.nome,
        $email: user.email,
        role: user.perfil,
    });
}

export const track = (event, properties = {}) => {
    if (!isEnabled)
        return;

    mixpanel.track(event, properties);
}

function handleDistinctId (user) {
    const distinctId = mixpanel.get_distinct_id()
    const isDistinctIdGuid = distinctId.length === 36

    if (!user && isDistinctIdGuid) {
        mixpanel.reset()
        console.log('Logout tracking...');
    }
}

function registerSuperProps (user) {
    const props = {
        app_version: packageInfo.version,
    }

    if (user) {
        props.plano = user.assinatura.trial ? 'Trial' : user.assinatura.plano.nome
        props.betaTester = user.corretoraPreferencias.betaTester
    }

    const utmKeywords = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_content', 'utm_term']
    const query = new URLSearchParams(location.search)
    utmKeywords.forEach((kw) => {
        const utmValue = query.get(kw)

        if (utmValue) props[kw + ' [last touch]'] = utmValue
    })

    mixpanel.register(props)
    window.mixpanelProperties = mixpanel.persistence.properties();
}
